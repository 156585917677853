<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :fields="fields"
      :row-data="row_data"
      post-api="/facility/AddFacility"
      finish-router="facility"
      permission="Fac_Create"
      @afterSubmit="goRole"
      @beforeSubmit="beforeSubmit"
    >
      <template v-slot:inputs="scope">
        <slot-inputs
          ref="slot_inputs"
          :root-item="scope.rootItem"
          :values="row_data['callBellConfig.contactEmails']"
          @updateValues="(value) => row_data['callBellConfig.contactEmails'] = value"
        />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields, { moduleMap, modules_default, reloadFieldData } from "@/views/facility/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import common from "@/common";
import SlotInputs from '@/components/FieldInputs';
import { expandObject } from '@/libs/ez-utils';

export default {
  name: "Add",
  components: {
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    SlotInputs,
  },
  data() {
    return {
      title: common.getMenuName('facility') + ' - ' + common.getI18n('common.add'),
      fields,
      row_data: {
        selectedModuleIDs: modules_default,
        selectedMenuStyleID: 1,
        selectedLanguageCode: 'zh-HK',
        bemSilentModeEndTime: '07:00',
      },
    }
  },
  // computed: {
  //   moduleMap() {
  //     return moduleMap
  //   }
  // },
  created() {
    reloadFieldData();
  },
  methods: {
    beforeSubmit(callback) {
      const rowData = expandObject(JSON.parse(JSON.stringify(this.$refs.edit_field.rowData)))
      if(rowData?.callBellConfig?.contactEmails) {
        rowData.callBellConfig.contactEmails = rowData.callBellConfig.contactEmails.join(';')
      }
      // if(!rowData?.selectedModuleIDs.includes(83)) { // not include "CallBell"
      if(!rowData?.selectedModuleIDs.includes(moduleMap['CallBell_Menu'])) {
        rowData.callBellConfig = null
      }
      if(rowData.contractEndDay === '') {
        rowData.contractEndDay = null
      }
      callback(true, rowData)
    },
    goRole: function (rowData, facilityID) {
      if (!common.checkPermission('Role_Create')) return false

      const that = this
      common.showConfirm(this.$t('facility.is_add_role'), '', function () {
        if (common.isAdmin()) {
          common.setAdminCompanyId(rowData.companyID)
          common.setAdminFacilityId(facilityID)
        }

        that.$router.push({name: 'role'})
      })
    },
  },
}
</script>

<style scoped>

</style>
